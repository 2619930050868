<template>
    <div class="m-6 flex flex-col flex-1 text-base-content">

        <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
            Loading
        </div>
        <div v-if="descriptionData && !isLoading">
            <div class="flex gap-6 flex-col">
                <div class="flex justify-between">
                    <div class='w-50'>
                        <span class="text-md font-medium">
                            Screening Package Name
                        </span>
                        <div v-if="editMode" class="mt-2 packageNameEditable flex justify-between">
                            <Input v-model="descriptionData.package_name" placeholder="Field Name" bg="white" :editMode='editMode' @focusInput='focusInput'
                                :disabled="(this.$route.params.action === 'view')" class="packageNameEditableInput" @removeEditablePackName='removeEditablePackName'>
                            </Input>
                            <font-awesome-icon @click="hideEditMode" v-on-click-outside="disableInputEditMode" icon="edit" class="mx-5 my-auto text-gray-300 editIcon"/>
                        </div>
                        <div v-else class="flex mt-2 packageNameInput">
                            <p 
                                :style="{ color: this.$route.params.action === 'view' ? '#CCCCCC'  : ''}"
                                class="pl-4 packageNameInput flex items-center gap-3 m-0 border border-solid border-box-stroke cursor-not-allowed py-2 rounded-lg break-all text-sm">
                                {{ descriptionData.package_name ? descriptionData.package_name : 'No package name' }}
                            </p>
                            <font-awesome-icon v-allow="'package.edit'" @click="handleClick" v-on-click-outside="disableInputEditMode" v-if="this.$route.params.action !== 'view'" class="mx-5 my-auto editIcon"
                                :style="{ color: this.$route.params.action === 'view' ? 'inherit' : 'hover:text-primary', cursor: this.$route.params.action === 'view' ? 'not-allowed' : 'pointer' }"
                                icon="edit" :class="{ 'not-allowed': this.$route.params.action === 'view' }" />
                        </div>
                    </div>
                    <div class='ml-8 w-50' >
                        <div class="text-md font-medium">
                            Entity Type
                        </div>
                        <input class="entityTypeInput border border-solid border-box-stroke cursor-not-allowed py-2 rounded-lg pl-4 py-2 mt-2 bg-gray-200" v-model="descriptionData.package_entity_type" :disabled="true"/>
                    </div>
                </div>

                <div class="flex flex-col gap-3 w-12/12">
                    <div>
                        <span class="text-base-content-300 text-md font-medium"> Description </span>
                        <font-awesome-icon v-allow="'package.edit'" @click="handleClickTextarea"  v-on-click-outside="disableEditMode" v-if="this.$route.params.action !== 'view'" class="mx-5 my-auto editIcon"
                            :style="{ color: this.$route.params.action === 'view' ? 'inherit' : 'hover:text-primary', cursor: this.$route.params.action === 'view' ? 'not-allowed' : 'pointer' }"
                            icon="edit" :class="{ 'not-allowed': this.$route.params.action === 'view', 'text-gray-300' : editTextArea }" />
                    </div>
                    <textarea :disabled="!checkPermission('package.edit') || this.$route.params.action === 'view' || !editTextArea"
                        v-model="descriptionData.package_description" :class="{'viewDescText' : !editTextArea}"  @blur='removeEditableMode'
                        class="textarea textarea-bordered rounded-lg focus:textarea-primary text-base-content-300 border-box-stroke descriptionInput"
                        placeholder="Description" id="" cols="30" rows="3" @focus='focusTextarea' ></textarea>
                </div>
            </div>
            <!-- <div v-else>loading...</div> -->

            <div class="mt-4 flex justify-end max-w-full gap-4" v-allow="'package.edit'">
                <Button v-if="this.$route.params.action !== 'view'" type="primary" text="Save" @click="updatePackage" :disabled="(this.$route.params.action === 'view')"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
            </div>

        </div>
    </div>
</template>
<script>
import Input from "@/components/input";
import axios from "@/axios";
import loader from "@/components/loader";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Button from "@/components/button"
import { checkPermission } from "@shared/utils/functions"

export default {
    name: "description",
    components: {
        Input,
        loader,
        Button,
    },
    props: [],

    data: () => ({
        editMode: false,
        descriptionData: null,
        isLoading: false,
        editTextArea: false,
        isTextAreaClicked: false,
        isInputClicked: false,
        // package:  {
        //   id: "",
        //   // package_name: "",
        //   // package_name_internal: "",
        //   package_description: "",
        //   // turn_around_time: null,
        //   // client_email_template: null,
        //   // candidate_email_template: null,
        //   // max_candidate_chase_email: null,
        //   // min_candidate_chase_email: null,
        //   // tenant_id: "3ea5e89b-1d80-4b7d-a4db-852d81126c8d",
        //   // is_custom: false,
        //   // mark_as_default: null
        // }
    }),
    async mounted() {
        await this.fetchScreeningData();
    },

    computed: {
        ...mapGetters(["getPackage"]),
    },
    methods: {
        checkPermission,
        handleClick() {
            if (this.$route.params.action !== 'view') {
                this.editMode = true;
            }
        },
        handleClickTextarea(){
            if (this.$route.params.action !== 'view') {
                this.editTextArea = !this.editTextArea;
            }        
        },
        disableEditMode(){
            if(!this.isTextAreaClicked)
                this.editTextArea = false;
            this.isTextAreaClicked = false
        },
        disableInputEditMode(){
            if(!this.isInputClicked)
                this.editMode = false;
            this.isInputClicked = false
        },
        focusTextarea() {
            this.isTextAreaClicked = true;
        },
        focusInput(val) {
            this.isInputClicked= val;
        },
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
            this.isLoading = true;
            if (!this.getPackage) {
                await this.fetchPackageData(this.$route.params.packageName)
                this.descriptionData = this.getPackage
            }
            this.descriptionData = this.getPackage
            this.isLoading = false;
        },

        async updatePackage() {
            if (this.$route.params.action == 'view') {
                return
            }
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}`;
            let regex_package_name = this.descriptionData?.package_name?.trimEnd()
            const payload = {
                package_name: regex_package_name,
                package_description: this.descriptionData.package_description,
            };
            try {
                await axios.put(url, payload);
                this.SET_PACKAGE = { ...this.getPackage, package_description: this.descriptionData.package_description }
                this.$toast.success("Package description updated.");
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "Could not update package.");
            }
        },
        hideEditMode(){
            this.editMode = false;
        },
        removeEditableMode() {
            if(this.editTextArea)  
                this.editTextArea = false;
        },
        removeEditablePackName(val) {
            this.editMode = val;
        },
    },
};
</script>
<style>

    .entityTypeInput{
        width:500px;
    }
    .packageNameInput,.packageNameEditableInput,.packageNameEditable{
        width:520px;
    }
    .packageNameEditableInput,.packageNameInput{
        height:2.7rem;
    }
    .descriptionInput{
        resize: none;
        border: 1px solid #e0dfdf !important;
    }
    .editIcon{
        font-size: 1.3rem;
    }
    .viewDescText{
        border: 1px solid #e0dfdf !important;
        background: none !important;
    }
</style>